import logo from './logo.svg';
import './App.css';

import { ChatAiWidget } from "@sendbird/chat-ai-widget";
import "@sendbird/chat-ai-widget/dist/style.css";

const App = () => {
    return (
        <ChatAiWidget
            applicationId="4EE942A3-25A1-4ACE-AB6B-054304449DE2" // Your Sendbird Application ID
            botId="lschatbot" // Your Bot ID
            //applicationId="AE8F7EEA-4555-4F86-AD8B-5E0BD86BFE67" // Your Sendbird Application ID
            //botId="khan-academy-bot" // Your Bot ID
        />
    );
}

/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
*/
export default App;
